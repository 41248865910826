var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    [_vm.block?.containerClass]: true,
  },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
    paddingTop: _vm.block?.componentStyle?.paddingTop + 'px',
    paddingBottom: _vm.block?.componentStyle?.paddingBottom + 'px',
  })},[(!_vm.isLoading)?_c('div',{staticClass:"common-social-media-cards__container w-100"},[(_vm.block.title)?_c('h2',{staticClass:"common-social-media-cards__title d-flex justify-center",class:{
        'pb-12': _vm.categoryLength > 1,
        'pb-1': _vm.categoryLength < 2,
      },domProps:{"innerHTML":_vm._s(_vm.block.title)}}):_c('div'),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column",style:(`--container-bg-color: ${_vm.block?.componentStyle?.backgroundColor};`)},[_c('div',{staticClass:"d-flex justify-content-center",class:{
          'pb-10': _vm.categoryLength > 1,
          'pb-1': _vm.categoryLength < 2,
        }},[(_vm.showLeftArrow)?_c('v-icon',{staticClass:"pb-1",attrs:{"size":"32","aria-label":"showLeftArrow"},on:{"click":_vm.scrollLeft}},[_vm._v("$testimonialLeftArrowIcon")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"common-social-media-cards__container-category-wrapper"},[(_vm.categoryLength > 1)?_c('div',{ref:"categoryContainer",staticClass:"common-social-media-cards__container-category d-flex justify-center align-items-center"},_vm._l((_vm.block.category),function(category){return _c('h4',{key:category.title,staticClass:"px-2 common-social-media-cards__category",class:{
                'common-social-media-cards__category--selected':
                  _vm.selected.title === category.title,
              },on:{"click":function($event){return _vm.categoryClicked(category)}}},[_vm._v("\n              "+_vm._s(category.title)+"\n            ")])}),0):_vm._e()]),_vm._v(" "),(_vm.showRightArrow)?_c('v-icon',{staticClass:"pb-1",attrs:{"size":"32","aria-label":"showRightArrow"},on:{"click":_vm.scrollRight}},[_vm._v("$testimonialRightArrowIcon")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column justify-xl-space-between common-social-media-cards__container-content",style:({
          backgroundColor:
            _vm.selected?.features[_vm.featureSelected]?.backgroundColor,
        })},[(_vm.featuresCount > 1)?_c('div',{staticClass:"common-social-media-cards__category-features-controls d-flex justify-content-center align-items-center"},[_c('v-btn',{attrs:{"aria-label":"Previous Feature","icon":"","ripple":false},on:{"click":_vm.selectPreviousFeature}},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("$testimonialLeftArrowIcon")])],1),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.currentFeatureIndex)+" / "+_vm._s(_vm.featuresCount))]),_vm._v(" "),_c('v-btn',{attrs:{"aria-label":"Next Feature","icon":"","ripple":false},on:{"click":_vm.selectNextFeature}},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("$testimonialRightArrowIcon")])],1)],1):_vm._e(),_vm._v(" "),(
            _vm.featureSelected !== -1 &&
            _vm.selected?.features[_vm.featureSelected]?.quote?.content?.text
          )?_c('v-container',{class:`common-social-media-cards__container-quote d-flex flex-column align-center ${
            _vm.block?.containerClass
          } ${_vm.categoryLength < 2 ? 'pt-0' : ''}`},[_c('h5',{staticClass:"common-social-media-cards__quote-text",style:({ color: _vm.block?.textColor }),domProps:{"innerHTML":_vm._s(_vm.selected?.features[_vm.featureSelected]?.quote?.content?.text)}}),_vm._v(" "),_c('div',{staticClass:"common-social-media-cards__container-author d-flex flex-column flex-md-row align-items-center"},[_c('nuxt-img',{staticClass:"rounded-circle",attrs:{"src":_vm.selected?.features[_vm.featureSelected]?.quote?.author
                  ?.avatar || '',"width":"48","height":"48","alt":"authorAvatar","loading":"lazy","format":"webp"}}),_vm._v(" "),_c('div',{staticClass:"common-social-media-cards__author-info d-flex flex-column align-items-center align-md-start"},[_c('p',{staticClass:"common-social-media-cards__author-name d-flex justify-center",domProps:{"innerHTML":_vm._s(
                  _vm.selected?.features[_vm.featureSelected]?.quote?.author?.name
                )}}),_vm._v(" "),_c('p',{staticClass:"common-social-media-cards__author-role-company",domProps:{"innerHTML":_vm._s(
                  _vm.selected?.features[_vm.featureSelected]?.quote?.author?.company
                )}})])],1),_vm._v(" "),(
              _vm.selected?.features[_vm.featureSelected]?.quote?.socialMedia?.logo
            )?_c('div',{staticClass:"common-social-media-cards__container-social-media d-flex align-items-center pt-4"},[_c('nuxt-img',{attrs:{"src":_vm.selected?.features[_vm.featureSelected]?.quote?.socialMedia
                  ?.logo || ''}}),_vm._v(" "),_c('p',{staticClass:"common-social-media-cards__social-media-text",domProps:{"innerHTML":_vm._s(
                _vm.selected?.features[_vm.featureSelected]?.quote?.socialMedia?.text
              )}})],1):_vm._e()]):_vm._e(),_vm._v(" "),(
            _vm.featureSelected !== -1 &&
            _vm.selected?.features[_vm.featureSelected]?.textAndImage.title
          )?_c('v-container',[_c('div',{staticClass:"common-social-media-cards__container-tai d-flex flex-column flex-xl-row justify-center align-items-center"},[_c('div',{staticClass:"d-flex flex-column common-social-media-cards__tai-info"},[_c('div',{staticClass:"common-social-media-cards__tai-container-text d-flex flex-column"},[_c('h3',{staticClass:"common-social-media-cards__tai-title",domProps:{"innerHTML":_vm._s(
                    _vm.selected?.features[_vm.featureSelected]?.textAndImage.title
                  )}}),_vm._v(" "),_c('p',{staticClass:"common-social-media-cards__tai-paragraph d-flex justify-content-center justify-content-xl-start",domProps:{"innerHTML":_vm._s(
                    _vm.selected?.features[_vm.featureSelected]?.textAndImage
                      .paragraph
                  )}})]),_vm._v(" "),(
                  _vm.selected?.features[_vm.featureSelected]?.textAndImage?.ctaButton
                    ?.text
                )?_c('div',{staticClass:"d-flex justify-content-center justify-content-xl-start"},[_c('v-btn',{staticClass:"common-social-media-cards__tai-cta-button",attrs:{"depressed":"","aria-label":_vm.selected?.features[_vm.featureSelected]?.textAndImage
                      ?.ctaButton?.text,"height":_vm.selected?.features[_vm.featureSelected]?.textAndImage
                      ?.ctaButton?.height,"width":_vm.selected?.features[_vm.featureSelected]?.textAndImage
                      ?.ctaButton?.width,"color":_vm.selected?.features[_vm.featureSelected]?.textAndImage
                      ?.ctaButton?.color,"outlined":_vm.selected?.features[_vm.featureSelected]?.textAndImage
                      ?.ctaButton?.outlined,"href":_vm.selected?.features[_vm.featureSelected]?.textAndImage
                      ?.ctaButton?.link,"target":_vm.isExternalLink(
                      _vm.selected?.features[_vm.featureSelected]?.textAndImage
                        ?.ctaButton?.link,
                      _vm.$config.nuxtBaseURL
                    )
                      ? '_blank'
                      : ''},on:{"click":_vm.heroButtonClicked}},[_c('span',{style:({
                      color:
                        _vm.selected?.features[_vm.featureSelected]?.textAndImage
                          ?.ctaButton?.textColor,
                      paddingLeft: '17px',
                      paddingRight: '17px',
                    }),domProps:{"innerHTML":_vm._s(
                      _vm.selected?.features[_vm.featureSelected]?.textAndImage
                        ?.ctaButton?.text
                    )}})])],1):_vm._e()]),_vm._v(" "),(_vm.selected?.features[_vm.featureSelected]?.textAndImage?.image)?_c('div',{staticClass:"align-self-center align-self-xl-start my-auto"},[_c('nuxt-img',{staticClass:"common-social-media-cards__tai-image",attrs:{"alt":"featuredImg","src":_vm.selected?.features[_vm.featureSelected]?.textAndImage?.image ||
                  '',"loading":"lazy"}})],1):_vm._e()])]):_vm._e()],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }